<template>
  <v-card :loading="loading" class="mx-auto" max-width="374">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img
      height="250"
      src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
      v-if="person != 0"
    ></v-img>
    <v-img
      height="250"
      src="https://cdn-images-1.medium.com/max/1024/1*eogFpsVgNzXQLCVgFzT_-A.jpeg"
      v-if="person == 0"
    ></v-img>

    <v-card-title v-if="person == 0">{{ title }} </v-card-title>
    <v-card-title v-if="person != 0">{{ person }} Person</v-card-title>
  
    <v-card-text>
      <v-row align="center" class="mx-0" v-if="person != 0">
        <v-icon class="mr-1 mb-2">mdi-food</v-icon>
        <div class="grey--text">Food & Beverage</div>
      </v-row>

      <v-row align="center" class="mx-0" v-if="person == 0">
        <v-icon class="mr-1 mb-1">mdi-toolbox</v-icon>
        <div class="grey--text">Services</div>
      </v-row>

       <div class="mt-4 text-caption" v-if="person == 0">
        {{description}}
      </div>

       <div class="mt-4 font-weight-bold text-subtitle-2">
        Branch - {{ branch }}
      </div>
      <div class="text-subtitle-1">
      {{address}}
      </div>

      <div class="mt-4 font-weight-bold  text-subtitle-2" v-if="person == 0">
        Service Provider
      </div>
      <div class="text-subtitle-1">
        {{ provider_name }}
      </div>
      <div class="mt-4 font-weight-bold  text-subtitle-2">Customer</div>
      <div class="text-subtitle-1">{{ customer }} <br> {{ contact }}</div>
      <div class="mt-4 font-weight-bold  text-subtitle-2">Remark</div>
      <div class="mb-4 text-subtitle-1">
        {{ remark }}
      </div>

    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-title>Time and Date</v-card-title>

    <v-card-text>
      <v-chip color="primary" label>
        <v-icon left> mdi-clock-outline </v-icon>
        {{ time }}
      </v-chip>
      <v-chip class="ma-2" color="primary" label>
        <v-icon left> mdi-calendar </v-icon>
        {{ date }}
      </v-chip>
    </v-card-text>
  </v-card>
</template>

<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
export default {
  data: () => ({
    domain: BASEURL,
    loading: false,
    selection: 1,
    booking_id: "",
    time: "",
    date: "",
    title: "",
    description: "",
    person: "",
    provider_id: "",
    customer: "",
    provider_name: "",
    contact: "",
    remark: "",
    branch:"",
    address: ""
  }),
  computed: {},
  created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has("id")) {
      const id = urlParams.get("id");
      this.booking_id = id;
      this.getBooking();
    }
  },
  watch: {},
  methods: {
    getBooking() {
      const params = new URLSearchParams();
      params.append("getBookingInfo", "done");
      params.append("booking_id", this.booking_id);
      axios({
        method: "post",
        url: this.domain + "/booking/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log(response.data.booking);
            this.time = response.data.booking[0].selected_time;
            this.date = response.data.booking[0].selected_date;
            this.description = response.data.booking[0].service_description;
            this.title = response.data.booking[0].service_title;
            this.provider_name = response.data.booking[0].provider_name;
            this.person = response.data.booking[0].person;
            this.customer = response.data.booking[0].customer_name;
            this.contact = response.data.booking[0].customer_contact;
            this.remark = response.data.booking[0].remark;
            this.branch = response.data.booking[0].branch_name;
            this.address = response.data.booking[0].address;

            this.getProviderInfo();
          } else {
            console.log("no booking");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
